import React from 'react'

interface PageWrapperProps {
  className?: string
  children: React.ReactNode
}

export const PageWrapper: React.FC<PageWrapperProps> = ({
  className,
  children
}) => <div className={className}>{children}</div>
